import React, { ReactElement, useEffect } from 'react';

import Amplify, { API } from 'aws-amplify';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { SWRConfig } from 'swr';

import { PoweredBy } from '../badge/PoweredBy';
import { pageview } from '../utils/Analytics';
import { AwsConfig } from '../utils/AwsConfig';
import { LayoutPage } from '../utils/Layout';
import { init } from '../utils/Sentry';

import '../styles/main.css';

init();

// TODO: Move to every page when SSR
Amplify.configure({ ...AwsConfig });

type IMyAppProps = AppProps & {
  Component: LayoutPage;
};

const MyApp = ({ Component, pageProps }: IMyAppProps) => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const getLayout = Component.getLayout ?? ((page: ReactElement) => page);

  return (
    <SWRConfig
      value={{
        fetcher: (url: string) => API.get('social-image', url, null),
        revalidateOnFocus: false,
      }}
    >
      {getLayout(<Component {...pageProps} />)}

      <PoweredBy />
    </SWRConfig>
  );
};

export default MyApp;
